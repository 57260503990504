import axios, { AxiosInstance, AxiosResponse, AxiosError, AxiosRequestConfig } from 'axios';
import { BaseResponse } from '../@types/base';
import { ENUM_CONFIG_API, STORAGE_KEYS } from './config';
import browserStored, { BrowserStored } from './browserStored';
import encrypt, { Encrypt } from './encrypt'
import environment from './environment';
class ApiModule {
  protected instance: AxiosInstance;
  protected encrypt: Encrypt;
  protected browserStored: BrowserStored

  constructor(isAuthentication?: boolean, configHeader?: Object) {
    this.encrypt = encrypt
    this.browserStored = browserStored
    this.instance = axios.create({
      baseURL: environment.baseUrl,
      timeout: ENUM_CONFIG_API.TIME_OUT,
      headers: configHeader ? Object.assign(
        {
          'Content-Type': "application/json",
          'X-Api-Key': environment.apiKey,
          'Accept': "application/json",
          'Authorization': isAuthentication ? "Bearer " + this.encrypt.decryptString(this.browserStored.getLocalStored(STORAGE_KEYS.ACCESS_TOKEN)) : ''
        },
        configHeader ? {} : configHeader
      ) : {
        'Content-Type': "application/json",
        'X-Api-Key': environment.apiKey,
        'Accept': "application/json",
        'Authorization': isAuthentication ? "Bearer " + this.encrypt.decryptString(this.browserStored.getLocalStored(STORAGE_KEYS.ACCESS_TOKEN)) : ''
      }
    })
  }


  /**
   * @returns BaseResponse
   */
  handleResponse<T>(response: AxiosResponse<BaseResponse<T>>): BaseResponse<T> {
    if (response.status === 200) {
      return response.data;
    } else {
      return {
        status: 500,
        data: null,
        detail: null
      };
    }
  }

  handleError<T>(error: AxiosError<BaseResponse<T>>): BaseResponse<T> {
    if (error.response != null) {
      return error.response.data;
    } else {
      // Error
      switch (error.message) {
        case 'Network Error':
          return {
            status: 500,
            data: null,
            detail: error.message
          };

        default:
          return {
            status: 500,
            data: null,
            detail: error.message
          };
      }
    }
  }

  get<T>(url: string, config?: AxiosRequestConfig) {
    return this.instance.get(url, config).then(this.handleResponse<T>).catch(this.handleError);
  }

  post<T>(url: string, data: any, config?: AxiosRequestConfig) {
    return this.instance.post(url, data, config).then(this.handleResponse<T>).catch(this.handleError);
  }
}

const apiModule: ApiModule = new ApiModule()

export type { ApiModule }

export default apiModule