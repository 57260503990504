import { checkUndefinedNumber } from "../../../utils/format";

export function calcTotalRentPrice(detail: any) {
    let price = 0;

    if (detail?.data?.is_product === false) {
        if (detail?.surchargeDate > 0 && detail?.data?.surcharge_time > 0) {
            price += (checkUndefinedNumber(detail?.data?.rental_price) + checkUndefinedNumber(detail?.data?.rental_price) * (detail?.data?.surcharge_time / 100) * detail?.surchargeDate) * checkUndefinedNumber(detail?.totalAmount);
        } else {
            price += checkUndefinedNumber(detail?.data?.rental_price) * checkUndefinedNumber(detail?.totalAmount);
        }
    } else {
        if (detail?.surchargeDate > 0 && detail?.data?.surcharge_time > 0) {
            price += checkUndefinedNumber(detail?.selectProductDetail?.rental_price) * (detail?.data?.surcharge_time / 100) * detail?.surchargeDate;
        }
        for (let i = 0; i < checkUndefinedNumber(detail?.accessories?.length); i++) {
            let diff = detail?.accessories[i].count - checkUndefinedNumber(detail?.totalAmount);
            if (diff > 0) {
                price += diff * checkUndefinedNumber(detail?.accessories[i]?.rental_price);
                if (detail?.surchargeDate > 0 && detail?.data?.surcharge_time > 0) {
                    price += checkUndefinedNumber(detail?.accessories[i]?.rental_price) * (detail?.data?.surcharge_time / 100) * detail?.surchargeDate;
                }
            }
        }
        price += checkUndefinedNumber(detail?.selectProductDetail?.rental_price) * checkUndefinedNumber(detail?.totalAmount);
    }

    return price;
}

export function calcTotalSellPrice(detail: any) {
    let price = 0;
    if (detail?.data?.is_product === false) {
        price += checkUndefinedNumber(detail?.data?.price) * checkUndefinedNumber(detail?.totalAmount);
    } else {
        for (let i = 0; i < checkUndefinedNumber(detail?.accessories?.length); i++) {
            let diff = detail?.accessories[i].count - checkUndefinedNumber(detail?.totalAmount);
            if (diff > 0) {
                price += diff * checkUndefinedNumber(detail?.accessories[i]?.price);
            }
        }
        price += checkUndefinedNumber(detail?.selectProductDetail?.price) * checkUndefinedNumber(detail?.totalAmount);
    }

    return price;
}

export function checkAvailableTotalAmount(selectProductDetail: any) {
    let isAvailabel = true;
    if (selectProductDetail) {
        if (selectProductDetail?.is_set === false) {
            if (selectProductDetail?.amount <= 0) {
                isAvailabel = false
            }
        } else {
            selectProductDetail?.accessories?.map((item: any) => {
                if (item.amount < 1) {
                    isAvailabel = false
                }
                return item;
            });
        }
    } else {
        isAvailabel = false
    }

    return isAvailabel;
}

export function handleAccessories(selectProductDetail: any) {
    if (selectProductDetail) {
        return selectProductDetail?.accessories?.map((item: any) => {
            item.allowed_split = selectProductDetail?.allowed_split ?? false;
            item.count = item.amount > 0 ? 1 : 0;
            return item;
        })
    }
    return [];
}

export function handleAttributeValues(datas: any, activeProduct: any) {
    if (datas && !activeProduct) {
        return datas?.attribute_values?.map((item: any) => {
            item?.values?.map((value: any) => {
                value.isOut = true;
                return value;
            })
            return item;
        })
    }

    return datas?.attribute_values?.map((item: any) => {
        item?.values?.map((value: any) => {
            if (activeProduct?.attribute_values?.includes(value.id) && activeProduct?.amount > 0) {

                value.isOut = false;
            } else {
                //tạm: có field amount đổi thành true
                value.isOut = false
            }

            return value;
        })
        return item;
    }) ?? [];
}

export function hasToIncreaseTotalAmount(detail: any, accessories: any) {
    let array = accessories?.map((e: any) => e.count);

    let lowestNumber = Math.min.apply(Math, array);
    for (let i = 0; i < array?.length; i++) {
        if (array[i] < lowestNumber) {
            return detail.totalAmount;
        }
    }
    return lowestNumber;
}

export function maxTotalAmount(detail: any, accessories: any) {
    let array = accessories?.map((e: any) => e.amount);

    let lowestNumber = Math.min.apply(Math, array);
    for (let i = 0; i < array?.length; i++) {
        if (array[i] < lowestNumber) {
            return detail.totalAmount;
        }
    }
    return lowestNumber;
}

export function diffInDay(d1: Date, d2: Date) {
    var t2 = d2.getTime();
    var t1 = d1.getTime();

    return Math.floor((t2 - t1) / (24 * 3600 * 1000));
}
export function changeProductListUrl(url: any) {
    if (url.indexOf("page") > -1) {
        url = url.slice(0, url.length - 1)
    }

    return url.slice(0, url.lastIndexOf("/"));
}

//Type: 0 là dành cho paging; 1: Filter
export function changeProductFilterUrl(url: any, param:any,type:any=0) {
    let result = "";
    let urlTemp = url;
    let strPage = "";
    if (url.indexOf("page") > -1) {
        urlTemp = url.split('page')[0];
        strPage = url.split('page')[1];
    }
    if (urlTemp.indexOf("?") > -1) {
        let temp = ((urlTemp.slice(-1) == "&" || urlTemp.slice(-1) == "?") ? "" : "&");
        result = urlTemp + temp + param;
        if(type==1){
            result += strPage;
        }
    }else{
        result = urlTemp + "?" + param
    }
    return result;
}

