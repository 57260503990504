
import Image from 'next/image';

const LoadingComponent = ({ loading = false }) => {

    return (loading ? <>
        <div className="w-[100vw] top-0 fixed h-[100vh] z-[30] bg-white opacity-70 flex flex-col items-center justify-center gap-[12px] ">

        </div>
        <div className="w-[100vw] top-0 fixed h-[100vh] z-[31] flex flex-col items-center justify-center gap-[12px] ">
            <Image
                priority={true}
                className='relative z-[32]'
                src={"/asset/image/head_logo.svg"}
                alt="product_tab_item"
                width={64}
                height={64}
                sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
            />
            <span className='text-sm text-[#08979C] font-bold'>LOADING</span>
        </div>
    </> : <></>);
}

export default LoadingComponent;
