
import CryptoJS from "crypto-js"
import environment from "./environment"

class Encrypt {
    keyEncrypt: string | undefined

    constructor() {
        this.keyEncrypt = environment.apiKeyEncrypt
    }

    existKeyEncrypt() {
        if (!this.keyEncrypt) {
            throw new Error(`Encrypt string errors`)
        } else {
            return this.keyEncrypt
        }
    }

    encryptString(value: string) {
        try {
            const key = this.existKeyEncrypt()
            return CryptoJS.AES.encrypt(value, key).toString()

        } catch (error) {
            throw new Error(`Encrypt string errors  ${error}`)
        }
    }

    decryptString(value: string) {
        try {
            const key = this.existKeyEncrypt()

            const bytes = CryptoJS.AES.decrypt(value, key)

            return bytes.toString(CryptoJS.enc.Utf8)
        } catch (error) {
            throw new Error(`Decrypt string errors  ${error}`)
        }
    }

    encryptObject(value: object) {
        try {
            const key = this.existKeyEncrypt()

            return CryptoJS.AES.encrypt(JSON.stringify(value), key).toString()
        } catch (error) {
            throw new Error(`Encrypt Object errors  ${error}`);
        }
    }

    decryptObject(value: string) {
        try {
            const key = this.existKeyEncrypt()

            var bytes = CryptoJS.AES.decrypt(value, key)
            return JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
        } catch (error) {
            throw new Error(`Decrypt Object errors  ${error}`)
        }
    }

}

const browserStored: Encrypt = new Encrypt()

export type { Encrypt }
export default browserStored;