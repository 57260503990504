// Next.js API route support: https://nextjs.org/docs/api-routes/introduction
import type { NextApiRequest, NextApiResponse } from 'next'
import apiModule from '../../utils/api'
export const baseURL = "/rm";
type Data = {
  name: string
}

export default function handler(
  req: NextApiRequest,
  res: NextApiResponse<Data>
) {
  res.status(200).json({ name: 'John Doe' })
}

export const getMenu = async () => {
  let response = await apiModule.get(baseURL + "/menu?use_type=0");
  if (response) {
    return response.data;
  }
}

export const getBanner = async () => {
  let response = await apiModule.get(baseURL + "/banner/landing-page");
  if (response) {
    return response?.data;
  }
}

export const getSpecialProduct = async () => {
  let response: any = await apiModule.get(baseURL + "/landing-page/get-product-home?page=1&limit=20");
  if (response) {
    return response.data?.results;
  }
}

export const getFeaturedProduct = async () => {
  let response = await apiModule.get(baseURL + "/landing-page/get-featured-products?page=1&limit=6");
  if (response) {
    return response.data;
  }
}

export const getProductParent = async (params: string) => {
  let response = await apiModule.get(baseURL + "/landing-page/product-parent-group" + params);
  if (response) {
    return response.data;
  }
}

export const getProductCategory = async () => {
  let response = await apiModule.get(baseURL + "/landing-page/get-group-filter");
  if (response) {
    return response.data;
  }
}

export const getProductCategorySearch = async (search: any) => {
  let response = await apiModule.get(baseURL + "/landing-page/get-group-filter?search=" + search);
  if (response) {
    return response.data;
  }
}

export const getProductList = async (params: any, body: any) => {
  let response = await apiModule.post(baseURL + "/landing-page/product-filter?" + params, body, {});
  if (response) {
    return response.data;
  }
}

export const chkComboProductList = async (params: any, body: any) => {
  return await apiModule.post(baseURL + "/landing-page/check-product-mix-filter?" + params, body, {});
}

export const getFilterSet = async () => {
  let response = await apiModule.get(baseURL + "/landing-page/get-filter");
  if (response) {
    return response.data;
  }
}

export const getFilterSetWithGroupId = async (group_id: string) => {
  let response = await apiModule.get(baseURL + "/landing-page/get-filter?group_url=" + group_id);
  if (response) {
    return response.data;
  }
}

export const getFilterSetWithSearch = async (search: string) => {
  let response = await apiModule.get(baseURL + "/landing-page/get-filter?search=" + search);
  if (response) {
    return response.data;
  }
}

export const getBranch = async () => {
  let response = await apiModule.get("/usm/company/all?limit=25&page=1");

  if (response) {
    return response.data;
  }
}



export const getUrls = async () => {
  let response = await apiModule.get("/rm/landing-page/urls");
  if (response) {
    return response.data;
  }
}

export const getProvince = async (search: string) => {
  let response = await apiModule.get("/rm/province?page=1&limit=1000&sort_by=name&search=" + search);
  if (response) {
    return response.data;
  }
}

export const getDistrict = async (parentId: string) => {
  let response = await apiModule.get("/rm/district?page=1&limit=1000&sort_by=name&parent_id=" + parentId);
  if (response) {
    return response.data;
  }
}

export const getWard = async (parentId: string) => {
  let response = await apiModule.get("/rm/ward?page=1&limit=1000&sort_by=name&parent_id=" + parentId);
  if (response) {
    return response.data;
  }
}

export const getFee = async (body: any) => {
  let response = await apiModule.post("/rm/cross-resource/ghtk-fee", body);
  if (response) {
    return response.data;
  }
}

export const searchMain = async (search: any) => {
  let response: any = await apiModule.get("/rm/landing-page/search-ldp?page=1&limit=10&search=" + search);
  if (response) {
    return response.data?.results;
  }
}

export const getProductMain = async () => {
  let response: any = await apiModule.get("/rm/landing-page/get-product-group-home");
  if (response) {
    return response.data;
  }
}


export const getPageSEO = async (url: any) => {
  let response: any = await apiModule.get("/rm/landing-page/get-seo-page?url=" + url);
  if (response) {
    return response.data;
  }
}

export const getProductBundling = async (search: any) => {
  let response: any = await apiModule.get("/rm/landing-page/get-product-bundling?" + search);
  if (response) {
    return response.data;
  }
}

//search page
export const searchPageApi = async (params: any) => {
  let response = await apiModule.get(baseURL + "/landing-page/search-product-and-product-group-ldp?" + params);
  if (response) {
    return response.data;
  }
}

export const searchKeywordApi = async (search: any) => {
  let response: any = await apiModule.get("/rm/keyword/get-keyword-info?search=" + search);
  if (response) {
    return response.data;
  }
}

export const getTypeUrl = async (url: any) => {
  let response: any = await apiModule.get("/rm/landing-page/get-type?url=" + url);
  if (response) {
    return response.data;
  }
}

export const searchKeyword = async (search: any) => {
  let response: any = await apiModule.get("/rm/landing-page/get-keywords?page=1&limit=10&search=" + search);
  if (response) {
    return response.data?.results;
  }
}

export const getProductList_keyword = async (params: any) => {
  let response: any = await apiModule.get("/rm/landing-page/get-product-by-keyword?" + params);
  if (response) {
    return response.data;
  }
}