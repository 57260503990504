import { setCookie, getCookie, deleteCookie } from 'cookies-next'

class BrowserStored {
    protected local: Storage | null = typeof window !== 'undefined' ? localStorage : null
    protected session: Storage | null = typeof window !== 'undefined' ? sessionStorage : null

    constructor(){}

    getLocalStored(key: string){
        let dataLocalStore = this.local?.getItem(key)
        if(dataLocalStore === null){
            throw new Error(`Get local stored item key ${key} not exist`)
        }
        
        return dataLocalStore ?? ''
    }

    setLocalStored(key: string, data: Object | string){
        if(this.local === null){
            return
        }
        return this.local.setItem(key, JSON.stringify(data))
    }

    removeLocalStored(key: string){
        let isExist = this.getLocalStored(key)
        if(!isExist) {
            throw new Error(`Key ${key} not exist`)
        }
        if(this.local === null){
            return
        }
        return this.local.removeItem(key)
    }

    getSessionStored(key: string){
        if(this.session === null){
            return
        }
        let dataStoreSession = this.session.getItem(key)
        if(dataStoreSession === null) {
            throw new Error(`Get session stored item key ${key} not exist`)
        }

        return this.session?.getItem(key) ?? ''
    }

    setSessionStored(key: string, data: Object | string){
        if(this.session === null){
            return
        }
        return this.session.setItem(key, JSON.stringify(data))
    }

    removeSessionStored(key: string){
        let isExist = this.getSessionStored(key)
        if(!isExist) {
            throw new Error(`Key ${key} not exist`)
        }
        if(this.session === null){
            return
        }
        return this.session.removeItem(key)
    }

    getCookieStored(key: string){
        return getCookie(key)
    }

    setCookieStored(key: string, data: Object | string){
        return setCookie(key, data)
    }

    removeCookieStored(key: string){
        return deleteCookie(key)
    }

    clearStored({ key, isCLearALL = false}: {key: string, isCLearALL?: boolean}){
        if(this.session === null || this.local === null){
            return
        }

        if(isCLearALL){
            this.local.clear()
            this.session.clear()
            deleteCookie(key)
        } else {
            this.local.removeItem(key)
            this.session.removeItem(key)
            deleteCookie(key)
        }
    }
}

const browserStored: BrowserStored = new BrowserStored()
export type { BrowserStored }
export default browserStored