export enum STORAGE_KEYS {
    ACCESS_TOKEN = 'accessToken',
    REFRESH_TOKEN = 'refreshToken',
    CARTS = 'carts',
    CUSTOMER = 'customer',
    ADDITIONAL_INFORMATION = 'additional_information',
    NOTE = 'note',
    PAYMENT = 'payment',
    name = 'name',
    email = 'email',
    phone = 'phone',
    showWarningCombo = 'warningCombo'
}

export enum ENUM_CONFIG_API {
    TIME_OUT = 30000
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    STORAGE_KEYS,
    ENUM_CONFIG_API
}
