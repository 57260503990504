import { TCreateOrderParam, TRsponseCreateOrder } from "../../@types/cart";
import apiModule from "../../utils/api";
import { covertObjectToStringParam } from "../../utils/format";


const MAIN_URL = "/rm/payment"
const METHOD_PAYMENT = '/method'
const CREATE_ORDER = '/create-order'

export const getPaymentMethod = async () => {
  let response: any = await apiModule.get(MAIN_URL + METHOD_PAYMENT);

  if (response && response.code === 1) {
    return response.data;
  } else {
    return []
  }
}

export const createBrankingCode = async (paramt: TCreateOrderParam) => {
  const convertParam = await covertObjectToStringParam(paramt)
  const url = `${MAIN_URL}${CREATE_ORDER}?${convertParam}`

  const response: any = await apiModule.post(url, {})

  if (response && response.code === 1) {
    return response.data as unknown as TRsponseCreateOrder;
  } else {
    return null
  }
}

export const getGeneralSetting = async () => {
  const response: any = await apiModule.get('/rm/general-setting', {})

  if (response && response.code === 1) {
    return response.data;
  } else {
    return null
  }
}

export const validateMoney = async (data: any) => {
  const response: any = await apiModule.post('/rm/landing-page/billing-information', data);

  if (response && response.code === 1) {
    return response.data;
  } else {
    return null
  }
}

export const createOrder = async (data: any) => {
  const response: any = await apiModule.post('/rm/landing-page/order', data);

  if (response && response.code === 1) {
    return response.data;
  } else {
    return null
  }
}

export const reportOrderError = async (data: any) => {
  const response: any = await apiModule.post('/rm/landing-page/order-error', data);

  if (response && response.code === 1) {
    return response.data;
  } else {
    return null
  }
}

export const getWorkingTime = async () => {
  const response: any = await apiModule.get('/rm/landing-page/working-time', {})

  if (response && response.code === 1) {
    return response.data;
  } else {
    return null
  }
}
